// eslint-disable react-hooks/exhaustive-deps

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Router, navigate } from '@reach/router';
import queryString from 'query-string';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { setTrackingCookie } from '@beelineloans/cx-library/src/utils/helpers';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import LoadingCircle from '@beelineloans/cx-library/src/components/LoadingCircle';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import GlobalStyle from '@beelineloans/cx-library/src/theme/global-styles';

const SetCookieAndRedirect = ({ location }) => {
  const ContainerWrap = styled(Container)`
    height: 100vh;
    .container-inner {
      padding: 0;
      height: 100%;
      div {
        margin-top: calc(50% - 50px);
      }
    }
  `;

  useEffect(() => {
    const queryParams = location.search ? queryString.parse(location.search) : {};
    let redirectTo = CONST.LINKS.EXTERNAL.POS.APPLY;
    setTrackingCookie();

    if (!queryParams.to) {
      console.error('Could not find redirect url in query');
    } else {
      redirectTo = queryParams.to;
    }

    setTimeout(() => {
      queryParams.to = undefined;
      const qString = queryString.stringify(queryParams);
      navigate(`${redirectTo}?${qString}`);
    }, 500);
  }, []);

  return (
    <ContainerWrap>
      <LoadingCircle />
    </ContainerWrap>
  );
};

SetCookieAndRedirect.propTypes = {
  location: PropTypes.object
};

const GoTo = () => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    <Router>
      <SetCookieAndRedirect default noThrow />
    </Router>
  </ThemeProvider>
);

export default GoTo;
